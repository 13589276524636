@import "../theme/colours.scss";
@import "../theme/sizes.scss";

.sidebar {
    background-color: $brand-primary-lighter-1;
    box-shadow: 2px 0px 8px 0px $drop-shadow-colour;

    padding-top: $masthead-content-below-whitespace;

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
    }

    a {
        font-weight: 700;
        display: block;
        padding: $padding-large $padding-large;
        text-transform: uppercase;
        text-decoration: none;
    }

    .activeroute {
        color: $brand-secondary;
    }
}
