@import "../../../theme/sizes.scss";

.fiftyFiftyRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "left right";
    gap: 0 $padding-large;

    > label {
        display: block;
        margin-top: $padding-large;

        > * {
            display: block;
            width: 100%;
            margin-top: $padding-small;
        }

        > .left {
            grid-area: date;
        }

        > .right {
            grid-area: time;
        }
    }
}
