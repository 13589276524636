@import "../../theme/sizes";

.message_box {
    flex: 0 1 auto;
    align-self: center;

    max-width: 75%;

    @include phone {
        max-width: 95%;
    }

    display: flex;
    flex-direction: column;

    .buttons {
        flex: 0 1 auto;
        align-self: stretch;
        align-items: flex-end;
        margin-top: $padding-medium;
        margin-bottom: $padding-medium;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-content: stretch;
        align-items: flex-start;

        button {
            width: 44%;
            margin-left: $padding-large;

            @include phone {
                margin-left: $padding-medium;
            }
        }
    }
}
