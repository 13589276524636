@import "../theme/sizes.scss";

$button-size: $masthead-height;
$image-size: 28px;

.close {
    width: $button-size;
    height: $button-size;
    cursor: pointer;
    padding: ($button-size - $image-size) / 2;
}
