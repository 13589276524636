@import "../../theme/sizes.scss";

.legend {
    composes: largeText from "../../theme/components.scss";
}

.formCardFieldset {
    border: none;
    margin: 0;

    .legend {
        margin-bottom: $padding-small;
    }

    .widgets {
        display: flex;
        flex-direction: column;

        > * {
            margin-top: $padding-small;
        }

        > label {
            display: block;
            margin-top: $padding-large;

            > * {
                display: block;
                width: 100%;
            }

            > div,
            > input,
            > select {
                margin-top: $padding-small;
            }
        }

        > button  {
            align-self: flex-end;
            margin-top: $padding-xxlarge;

            min-width: 45%;

            @include handheld {
                min-width: 80%;
            }

            @include phone {
                min-width: 100%;
            }

            &:first-child {
                margin-top: $padding-medium;
            }
        }
    }
}
