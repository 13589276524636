@import "../../theme/colours.scss";
@import "../../theme/sizes.scss";

$indicator-size: $spinner-size;
$indicator-spacing: 14px;

.loadingButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .indicator {
        pointer-events: none;
        margin: 0 $indicator-spacing;
        display: inline-block;
        min-width: $spinner-size;
        min-height: $spinner-size;
    }
}
