@import "../../../theme/sizes.scss";

.balanceRow {
    display: flex;
    align-content: stretch;
    align-items: center;

    .staticText {
        flex: 0 1 auto;
        margin-right: $padding-small;
    }
}
