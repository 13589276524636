@import "../../theme/colours";
@import "../../theme/sizes";

.pane {
    composes: normalText from "../../theme/components.scss";

    border-style: solid;
    border-width: 2px;

    display: flex;
    align-content: stretch;
    align-items: flex-start;

    > .content {
        padding: $padding-large 0 $padding-large $padding-large;

        flex: 1 1 auto;

        display: flex;

        > .message {
            flex: 1 1 auto;
            align-self: center;
        }

        > .iconImage {
            flex: 0 1 auto;

            width: 28px;
            height: 28px;
            margin-right: $padding-large;
        }
    }

    > .dismiss {
        flex: 0 1 auto;
        margin: 3px;
    }
}
