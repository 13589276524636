
@keyframes fadein {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.waitAndAppear {
    animation: fadein 300ms ease-in;
}
