@import "../../theme/sizes";
@import "./internal/sizes.scss";

.actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: stretch;
    align-items: flex-start;

    height: $quick-icon-size;
}
