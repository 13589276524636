@import "../../theme/sizes.scss";

.closableOverlay {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    align-items: flex-start;

    .close {
        flex: 0 1 auto;
        align-self: flex-end;
    }

    .contents {
        margin: $padding-medium;
        flex: 1 1 auto;
        align-self: stretch;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-content: stretch;
        align-items: stretch;
    }
}
