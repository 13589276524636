@import "../theme/colours";
@import "../theme/sizes";

.card {
    background-color: $card-background;
    color: $text-on-card;
    box-shadow: 2px 2px 6px 0px $drop-shadow-colour;
    padding: $card-padding 0;

    // By being at least this height a graphical glitch is prevented when adding the first row
    // Without the min height, the empty message would dissapear making the card 0 tall and then growing to the row height
    // it is better to not suddently shrink, instead maintain height and then grow
    min-height: $empty-message-height;
    box-sizing: content-box;

    > :not(.full) {
        margin-left: $card-padding;
        margin-right: $card-padding;
    }

    > .full {
        margin: 0;
    }

    > :first-child {
        margin-top: $card-padding / 2;
    }

    a,
    a:visited,
    a:active,
    a:focus {
        color: $brand-secondary;
    }

    a:hover {
        color: $brand-primary;
    }
}
