@import "../../theme/colours";
@import "../../theme/sizes";

$button-size: $masthead-height;
$bar-size: $masthead-height / 12;

.burger {
    width: $button-size;
    height: $button-size;
    padding: (($bar-size * 2) + 1) ($bar-size * 2);

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: stretch;
    align-items: stretch;

    cursor: pointer;

    &:hover,
    &:active {
        background-color: $brand-primary-lighter-1;
    }

    .bar {
        background-color: $text-on-brand-primary;
        flex: 0 1 auto;
        height: $bar-size;
    }
}
