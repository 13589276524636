@import "../../theme/sizes.scss";

$button-size: 24px;
$image-size: 12px;

.close {
    width: $button-size;
    height: $button-size;
    cursor: pointer;
    padding: ($button-size - $image-size) / 2;
}
