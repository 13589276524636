@import "../../../theme/colours";
@import "../../../theme/sizes";

$editing-border-size: 2px;
$editing-row-padding-top-bottom: $card-row-padding-top-bottom - $editing-border-size;
$editing-row-padding-left-right: $card-row-padding-left-right - $editing-border-size;

.betRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: flex-start;

    overflow: hidden;

    &.editing {
        border: $brand-primary-lighter-1 $editing-border-size dashed;
        padding: $editing-row-padding-top-bottom $editing-row-padding-left-right;
    }

    > .fill_available {
        flex: 1 1 auto;

        &.cancelled {
            color: $text-on-card-inactive;

            > .row {
                & .with_divisor {
                    border-left-color: $text-on-card-inactive;
                }
            }
        }

        > .row {
            display: flex;
            margin-bottom: $padding-small;

            & .with_divisor {
                margin-left: $padding-medium;
                padding-left: $padding-medium;
                border-left: 2px solid $brand-primary;
            }

            > * {
                &:not(:first-child) {
                    margin-left: $padding-medium;
                }
            }
        }
    }

    > .right {
        flex: 0 1 auto;
    }
}
