@import "../../../theme/colours";
@import "../../../theme/sizes";

$border-size: 1px;
$border-colour: $brand-primary-lighter-1;
$border-padding: $padding-medium;

.links {
    ul {
        padding: 0;

        li {
            list-style: none;

            display: flex;
            flex-direction: column;
            align-items: stretch;
            position: relative;

            a {
                flex: 0 1 auto;
                padding: $padding-medium-large $padding-medium;
                text-decoration: none;
                font-size: large;

                &.activeroute {
                    color: $brand-secondary;
                }

                &:hover {
                    background-color: $brand-primary-lighter-1;
                }
            }

            &:first-child:before,
            &:after {
                content: "";
                border-style: solid;
                border-color: $brand-primary-lighter-1;

                border-width: 0 0 $border-size 0;
                position: absolute;
                left: $border-padding;
                right: $border-padding;
                bottom: 0;
                pointer-events: none;
            }

            &:first-child:before {
                border-width: $border-size 0 0;
                top: 0;
            }
        }
    }
}
