@import "../../theme/sizes.scss";

.page {
    display: grid;

    &.dead_center {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        @include desktop {
            grid-template-columns: 31% 1fr 31%;
            grid-template-rows: 1fr 2fr 1fr;
            grid-template-areas: ". . ." ". middle_cell ." ". . .";
        }

        @include handheld {
            grid-template-columns: 10% 1fr 10%;
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-areas: ". . ." ". middle_cell ." ". . .";
        }

        @include phone {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-template-areas: "middle_cell";
        }

        > .primary {
            grid-area: middle_cell;
        }
    }

    &.master_detail {
        @include not-phone {
            grid-template-columns: 1fr 1fr minmax(350px, 1fr);
            grid-template-rows: 1fr;
            grid-template-areas: "primary primary secondary";
        }

        @include phone {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto;
            grid-template-areas: "primary" "secondary";
        }

        > .primary {
            grid-area: primary;
            overflow: hidden;

            @include desktop {
                padding: $masthead-content-below-whitespace ($padding-xlarge / 2) $masthead-content-below-whitespace $padding-xlarge;

                + .secondary {
                    padding: $masthead-content-below-whitespace $padding-xlarge $padding-large ($padding-xlarge / 2);
                }
            }

            @include handheld {
                padding: $padding-large;

                + .secondary {
                    padding: $padding-large $padding-large $padding-large ($padding-large / 2);
                }
            }

            @include phone {
                padding: $padding-medium $padding-medium ($padding-medium / 2);

                + .secondary {
                    padding: ($padding-medium / 2) $padding-medium $padding-medium;
                }
            }
        }

        > .secondary {
            grid-area: secondary;
            overflow: hidden;
        }
    }
}
