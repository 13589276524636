@import "../theme/colours";
@import "../theme/sizes";

.row {
    padding: $card-row-padding-top-bottom $card-row-padding-left-right;
    background-color: $card-background;

    &.alternative {
        background-color: $card-background-alternative;
    }

    &.void {
        background-color: $brand-primary;
        padding: 0;
    }

    &.clickable {
        cursor: pointer;

        &:hover {
            background-color: $brand-primary-lighter-1;
            color: $text-on-brand-primary;
        }
    }
}
