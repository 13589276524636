$desktop-minimum-width: 960px;  // Matches app code getDesktopMinimumWidth()
$tablet-minimum-width: 768px;  // Matches app code getTabletMinimumWidth()

@mixin desktop {
    @media (min-width: #{$desktop-minimum-width}) {
        @content;
    }
}

@mixin handheld {
    @media (max-width: #{$desktop-minimum-width - 1}) {
        @content;
    }
}

@mixin phone {
    @media (max-width: #{$tablet-minimum-width - 1}) {
        @content;
    }
}

@mixin not-phone {
    @media (min-width: #{$tablet-minimum-width}) {
        @content;
    }
}

$form-input-height: 32px;

$spinner-size: 16px;
$button-height: $form-input-height;

$padding-small: 4px;
$padding-medium: 8px;
$padding-medium-large: 12px;
$padding-large: 16px;
$padding-xlarge: 24px;
$padding-xxlarge: 32px;

$masthead-height: 48px;
$masthead-content-below-whitespace: $padding-xlarge;

$sidebar-width: 200px;

$card-padding: $padding-large;
$card-row-padding-top-bottom: $padding-medium;
$card-row-padding-left-right: $padding-large;

$empty-message-height: 28px;
