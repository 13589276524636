
@import "../theme/_colours.scss";

$spinner-normal-colour: $brand-primary;

@mixin makeSpinner($size, $dimension, $borderSize: 4px) {
    display: inline-block;
    width: $size;
    height: $size;

    &:after {
      content: '\200B';
      display: block;
      width: $dimension;
      height: $dimension;
      border-radius: 50%;
      animation: spinner 1s linear infinite;

      border: $borderSize solid;
      border-color: $spinner-normal-colour transparent;
    }
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
