@import "../../../theme/sizes";

.transactionRow {
    display: flex;
    overflow: hidden;

    > .left {
        flex-grow:1;

        overflow: hidden;
        margin-right: $padding-large;
    }

    > .right {
        flex-shrink: 0;
        align-self: center;
    }
}
