@import "../../../../theme/colours";
@import "../../../../theme/sizes";
@import "../../../../theme/z-indexes";

.phone {
    position: fixed;
    z-index: $z-action-contents;
    padding: $padding-medium;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;

    > * {
        flex: 1 0 auto;
        max-width: 100%;
    }
}
