@import "../theme/colours";
@import "../theme/sizes";

.pill {
    border-radius: 12px;
    font-size: 10px;
    line-height: 10px;
    padding: 3px 10px;

    &.primary {
        background-color: $brand-primary;
        color: $text-on-brand-primary;
    }

    &.secondary {
        background-color: $brand-secondary;
        color: $text-on-brand-secondary;
    }

    &.warning {
        background-color: $errorColour;
        color: $text-on-brand-primary;
    }
}
