@import "../theme/colours.scss";
@import "../theme/sizes.scss";

.button {
    height: 32px;
    font-weight: 700;
    padding: 0 $padding-large;

    color: $button-primary-text;

    &:focus {
        box-shadow: 1px 1px 6px 0px $drop-shadow-colour;
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    &.primary {
        background-color: $button-primary-background-normal;
        border: none;

        &:hover {
            background-color: $button-primary-background-hover;
        }
        &:disabled {
            background-color: $button-primary-background-disabled;
        }
        &:active {
            background-color: $button-primary-background-active;
        }
    }

    &.secondary {
        border: 1px solid $button-secondary-border;
        background-color: $button-secondary-background-normal;

        &:hover {
            background-color: $button-secondary-background-hover;
        }
        &:disabled {
            background-color: $button-secondary-background-disabled;
        }
        &:active {
            background-color: $button-secondary-background-active;
        }
    }
}
