
$brand-primary: #213950;
$brand-secondary: rgb(138, 255, 42);

$brand-primary-lighter-1: lighten($brand-primary, 10%);

$brand-primary-lighter-3: lighten($brand-primary, 74.5%);
$brand-primary-lightest: lighten($brand-primary, 75.5%);

$brand-secondary-lighter-1: lighten($brand-secondary, 10%);
$brand-secondary-lighter-2: lighten($brand-secondary, 20%);
$brand-secondary-lighter-3: lighten($brand-secondary, 30%);
$brand-secondary-lightest: lighten($brand-secondary, 40%);

$text-on-brand-primary: $brand-primary-lightest;
$text-on-brand-secondary: $brand-primary;
$text-on-card: $text-on-brand-secondary;
$text-on-card-inactive: rgba($text-on-card, 0.4);

$errorColour: #cc0000;
$errorColour-as-background: lighten($errorColour, 59%);
$text-on-errorColour: $brand-primary-lightest;

$successColour: $brand-secondary;
$successColour-as-background: lighten($successColour, 59%);
$text-on-successColour-as-background: darken($successColour, 31%);

$card-background: $brand-primary-lightest;
$card-background-alternative: $brand-primary-lighter-3;

$brand-primary-darker: #131a21;
$brand-secondary-darker: darken($brand-secondary, 10%);

$button-primary-background-normal: $brand-secondary;
$button-primary-background-active: $brand-secondary-darker;
$button-primary-background-hover: $brand-secondary-lighter-1;
$button-primary-background-disabled: $brand-secondary-lighter-2;
$button-primary-text: $text-on-brand-secondary;

$button-secondary-border: $brand-secondary;
$button-secondary-background-normal: $brand-primary-lightest;
$button-secondary-background-active: $brand-secondary-darker;
$button-secondary-background-hover: $brand-secondary-lighter-1;
$button-secondary-background-disabled: $brand-secondary-lighter-2;
$button-secondary-text: $text-on-brand-secondary;

$drop-shadow-colour: rgba($brand-primary, 0.45);
$drop-shadow-colour-lighter: lighten($drop-shadow-colour, 10%);
$overlay-background-colour: rgba($brand-primary-darker, 0.95);

$input-border: $brand-primary;
$input-background: $brand-primary-lightest;
$input-background-disabled: $brand-primary-lighter-3;
