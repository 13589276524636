@import "../../../theme/colours";

.messageText {
    composes: normalText from "../../../theme/components.scss";
    color: $errorColour;
}

.messageText:empty::after {
    content: '\200B';
    visibility: hidden;
}

$fadeAnimLength: 300ms;

.messageAnimEnter {
    opacity: 0;
}
.messageAnimEnterActive {
    opacity: 1;
    transition: opacity $fadeAnimLength;
}
.messageAnimExit {
    opacity: 1;
}
.messageAnimExitActive {
    opacity: 0;
    transition: opacity $fadeAnimLength;
}
.messageAnimExitDone {
    /* Stay invisible as the old text is still applied to support the fade out */
    opacity: 0;
}
