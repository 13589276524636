@import "./theme/colours";
@import "./theme/sizes.scss";
@import "./theme/z-indexes.scss";

.root {
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-content: stretch;

    overflow-x: hidden;
    overflow-y: overlay;

    &.overlayOpen {
        overflow-y: hidden;
    }

    > header {
        z-index: $z-navbar;
        flex: 0 1 $masthead-height;
    }

    > div {
        flex: 1 1 auto;
    }

    > .app {
        display: flex;

        .sidebar {
            flex: 0 0 $sidebar-width;
            max-width: $sidebar-width;
            align-items: stretch;

            overflow: hidden;
        }

        .content {
            position: relative;
            flex: 1 1 auto;
            color: $text-on-brand-secondary;
        }
    }
}
