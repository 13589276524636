.expandingVoid {
    animation: grow 480ms;

    > * {
        flex: 1 0 auto;
    }
}

@keyframes grow {
    0% {
        max-height: 0;
    }
    60% {
        max-height: 48px;
    }
}

.scalingContents {
    transform: scaleX(0.95) scaleY(0) translateY(-50%) translateY(24px);
    animation: scaled 480ms;
}

@keyframes scaled {
    0% {
        transform: scaleX(0.95) scaleY(0) translateY(-50%) translateY(24px);
    }
    29% {
        transform: scaleX(0.95) scaleY(0) translateY(-50%) translateY(24px);
    }

    100% {
        transform: scaleX(1.0) scaleY(1.0) translateY(0)  translateY(-0px);
    }
}
