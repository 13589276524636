@import "../theme/colours.scss";
@import "../theme/sizes.scss";

$masthead-background: $brand-primary;
$masthead-text-colour: $text-on-brand-primary;

.masthead {
    background: $masthead-background;
    color: $masthead-text-colour;
    height: $masthead-height;

    box-shadow: 0px 6px 8px 0px $drop-shadow-colour;

    display: flex;

    ul {
        font-size: 18px;
        height: $masthead-height;
        display: flex;
        flex-grow: 1;
        align-content: stretch;
        align-items: center;

        margin: 0;
        padding: 0 0 0 30px;
    }

    li {
        margin-right: 40px;
        flex: 0 1 auto;

        list-style: none;
        display: inline-block;

        a {
            text-decoration: none;
        }

        &:first-child {
            flex-grow: 1;
            font-weight: 800;
        }
    }
}
