@import "../../theme/sizes.scss";

.actions {
    display: grid;
    grid-template-rows: 1fr;

    @include not-phone {
        grid-template-columns: 1fr;
        gap: $padding-medium 0;
        grid-template-areas: ".";
    }

    @include phone {
        grid-template-columns: repeat(2, 1fr);
        gap: $padding-medium $padding-medium;
        grid-template-areas: "even-area odd-area";

        > :nth-child(odd) {
            grid-area: odd-area;
        }

        > :nth-child(even) {
            grid-area: even-area;
        }
    }
}
