@import "../../theme/sizes.scss";

.scoreInput {
    > * {
        &:not(:first-child) {
            margin-left: $padding-medium;
        }
    }

    .dash {
        font-weight: 900;
    }
}
