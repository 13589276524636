@import "../../../theme/colours";
@import "../../../theme/sizes";
@import "../../../spinner/spinner-mixin.scss";
@import "./sizes.scss";

.action {
    &.loading,
    &.inactive {
        opacity: 0.25;
    }

    @include phone {
        padding: $padding-large;
        border-top: $drop-shadow-colour solid 1px;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: stretch;
        align-items: center;
        font-size: larger;
        font-weight: 600;

        &.clickable {
            &:active {
                background-color: $drop-shadow-colour-lighter;
            }
        }

        &:last-child {
            border-bottom: $drop-shadow-colour solid 1px;
            margin-bottom: $padding-xxlarge;
        }

        > img {
            width: 24px;
            height: 24px;
            margin-right: $padding-large;
        }

        > .tiny_spinner {
            @include makeSpinner(24px, 16px, 2px);
            transform: translate(0px, 1px);
            margin-right: $padding-large;
        }
    }

    @include not-phone {
        margin-left: $padding-small;
        flex: 0 1 auto;
        padding: 2px;

        border: 1px solid $brand-primary;
        width: $quick-icon-size;
        height: $quick-icon-size;

        opacity: 0.5;
        position: relative;

        align-self: center;
        user-select: none;

        > img {
            width: $quick-icon-size - 6;
            height: $quick-icon-size - 6;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 2px;
        }

        > .tiny_spinner {
            @include makeSpinner(12px, 9px, 2px);
            transform: translate(0px, 1px);
        }

        &.clickable {
            &:hover {
                cursor: pointer;
                opacity: 1;
                box-shadow: 1px 1px 6px 0px $drop-shadow-colour;
            }
        }
    }
}
