@import "../theme/sizes";

.container {
    text-align: center;
    padding: $padding-xlarge $padding-medium;

    line-height: 1.25;

    img {
        margin-bottom: $padding-large;
    }

    h1 {
        margin-bottom: $padding-xlarge;
    }
}
