@import "../../../theme/colours";

.verticalDots {
    color: $drop-shadow-colour;
    font-size: 25px;
    font-weight: 900;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    height: 40px;
    width: 40px;
    user-select: none;

    // Knowingly eat into the rows padding
    margin-right: -15px;
}
