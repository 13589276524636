@import "../theme/colours";
@import "../theme/z-indexes";

.overlay {
    background-color: $overlay-background-colour;
    position: fixed;
    z-index: $overlay;
    height: 100vh;
    left: 0;
    right: 0;
    top: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
}

$animLength: 150ms;

.animEnter {
    background-color: transparent;
}
.animEnterActive {
    background-color: $overlay-background-colour;
    transition: background-color $animLength ease-out;
}
.animExit {
    background-color: $overlay-background-colour;
}
.animExitActive {
    background-color: transparent;
    transition: background-color $animLength ease-in;
}
