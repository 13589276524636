@import "../../theme/colours";

$slideTime: 90ms;

.animEnter {
    transform: translateX(-100%);
}
.animEnterActive {
    transform: translateX(0%);
    transition: transform $slideTime ease-in-out;
}
.animExit {
    transform: translateX(0%);
}
.animExitActive {
    transform: translateX(-100%);
    transition: transform $slideTime ease-in-out;
}
