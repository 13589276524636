@import "./theme/colours";
@import "./theme/sizes.scss";
@import "./theme/z-indexes.scss";

html,
body {
    margin: 0;
    padding: 0;
}

body {
    background-color: $brand-primary-lighter-3;
    color: $text-on-brand-primary;

    font-size: 14px;
    font-family: Roboto, Arial, sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

input,
select {
    padding: 0 $padding-medium;
    background-color: $input-background;
    border: $input-border solid 1px;
    height: $form-input-height;
    box-sizing: border-box;

    &:disabled {
        background-color: $input-background-disabled;
    }

    &:focus {
        box-shadow: 1px 1px 6px 0px $drop-shadow-colour;
    }
}

input:placeholder-shown {
    /* Firefox applies an error shadow on empty inputs if they have had then lost focus
       we only want the error to show if there are contents */
    box-shadow: none;
}

a,
a:visited,
a:active,
a:focus {
    color: $text-on-brand-primary;
}

a:hover {
    color: $brand-secondary;
}

h1 {
    margin-bottom: $padding-large;
}

p {
    margin-block-start: 0;
}
