.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    overflow: hidden;
    line-height: 2;

    > .name {
        flex: 1 1 auto;
        align-self: auto;
    }

    > .balance {
        flex: 0 0 auto;
        align-self: auto;
    }
}
