@import "../../../theme/colours";
@import "../../../theme/sizes";
@import "../../../theme/z-indexes";

$burger-menu-background: $brand-primary;

.burger {
    position: absolute;
    z-index: $overlay-contents;
    left: 0;
    top: 0;
    bottom: 0;

    width: 300px;
    background-color: $burger-menu-background;
    box-shadow: 1px 0 10px 0px $drop-shadow-colour;

    display: flex;
    flex-direction: column;

    .top {
        flex: 0 1 auto;
        align-self: flex-end;
    }

    .links {
        flex: 0 1 auto;
        align-self: stretch;
    }
}
