@import "../theme/sizes.scss";
@import "./spinner-mixin.scss";

$spinner-dark-colour: $brand-secondary;
$inner-size: $spinner-size * 0.75;

.spinner {
    @include makeSpinner($spinner-size, $inner-size);

    &.dark {
      &:after {
        border-color: $spinner-dark-colour transparent;
      }
    }
}
