@import "../../../theme/sizes";

$icon-size: 12px;

.name {
    font-weight: 600;

    em {
        margin-left: $padding-medium;
        font-weight: 500;
        font-size: 13px;
    }
}

.time {
    margin-top: $padding-small;
    display: flex;
    align-items: center;

    .icon {
        width: $icon-size;
        margin-right: $padding-small;
        user-select: none;
    }
}
