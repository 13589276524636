@import "../../theme/colours";
@import "../../theme/sizes";

.header {
    font-weight: 700;
    font-size: 18px;

    position: relative;

    &:after {
        content: "";
        background: $brand-primary;
        background: linear-gradient(90deg, $brand-primary 0%, $brand-primary 25%, rgba($brand-primary, 0.0) 100%);
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 2px;
        right: 45%;

        @include handheld {
            right: 25%;
        }
    }
}